import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Ramki w dokumencie HTML. Czym są i czy mają wpływ na SEO?",
        description:
          "Dowiedz się, czym są ramki znajdujące się w kodzie HTML oraz sprawdź, czy stosowanie ich może mieć negatywny wpływ na pozycję strony w wynikach wyszukiwania.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/czym-sa-ramki-w-dokumencie-html/",
                name: "Ramki w dokumencie HTML. Czym są i czy mają wpływ na SEO?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/czym-sa-ramki-w-dokumencie-html",
            text: "Czym są ramki w dokumencie HTML?",
          },
        ]}
      />
      <Article img={<img src={data.file.childImageSharp.fluid.src} alt={"Czym są ramki w dokumencie HTML?"} />}>
        <h1>Ramki w dokumencie HTML – czym są?</h1>
        <p>
          <strong>
            W tym artykule przyjrzymy się bliżej jednemu z ważnych elementów struktury strony – ramkom w dokumencie
            HTML. Dowiesz się, czym są, jak je tworzyć i jak wpłynąć na ich wygląd za pomocą CSS.
          </strong>
        </p>
        <h2>Ramki HTML – co to jest?</h2>
        <p>
          Ramki (ang. frames) w języku HTML to{" "}
          <strong>technika pozwalająca na podzielenie okna przeglądarki na kilka niezależnych sekcji</strong>, z których
          każda może wyświetlać inną stronę internetową. Ramki zostały wprowadzone do specyfikacji HTML 4.0 jako sposób
          na ułatwienie nawigacji oraz zarządzania treścią na stronach internetowych. Dzięki ramkom można było np.
          umieścić menu nawigacyjne w jednej sekcji, a treść strony w drugiej, co pozwalało na łatwiejsze poruszanie się
          po witrynie.
        </p>
        <p>
          Jednakże mimo swojej użyteczności, ramki w HTML mają także swoje wady. Przede wszystkim ich stosowanie może
          powodować problemy z kompatybilnością między różnymi przeglądarkami internetowymi, co może prowadzić do
          nieprawidłowego wyświetlania strony. Ponadto, ramki mogą utrudniać indeksowanie strony przez wyszukiwarki
          internetowe, co negatywnie wpływa na jej pozycjonowanie w wynikach wyszukiwania.
        </p>
        <h2>Wykorzystanie ramek iframe</h2>
        <p>
          Tag iframe pozwala na osadzenie na stronie internetowej treści z innych źródeł, takich jak strony internetowe
          czy materiały multimedialne.{" "}
          <strong>
            Ramka iframe może być szczególnie przydatna w przypadku integracji z serwisami takimi jak YouTube czy Google
            Maps
          </strong>
          . Aby stworzyć ramkę iframe, wystarczy dodać odpowiedni tag z atrybutem src wskazującym na adres URL osadzanej
          treści.
        </p>
        <h2>Stylizacja ramek za pomocą CSS – border html</h2>
        <p>
          Aby nadać ramkom odpowiedni wygląd, warto skorzystać z arkuszy stylów CSS. Dzięki nim można kontrolować takie
          właściwości jak szerokość, wysokość, tło, marginesy czy obramowanie. W przypadku obramowania (border) można
          zdefiniować jego rodzaj (np. solid, dashed, dotted), grubość oraz kolor. Dodatkowo, za pomocą CSS można
          zaokrąglić narożniki ramek, co pozwala na uzyskanie bardziej nowoczesnego i estetycznego efektu.
        </p>
        <h2>HTML CSS</h2>
        <p>
          Aby zilustrować omówione zagadnienia, przedstawimy prosty przykład użycia ramki w dokumencie HTML oraz jej
          stylizacji za pomocą CSS. Wykorzystamy tag div oraz arkusz stylów do stworzenia ramki o szerokości 300px,
          wysokości 200px, czerwonym obramowaniu o grubości 2px oraz zaokrąglonych narożnikach.
        </p>
        <h3>Kod HTML</h3>
        <p>```html</p>
        <p>Treść ramki</p>
        <h3>Kod CSS (styles.css)</h3>
        <p>```css</p>
        <code>
          {`.ramka {`}
          <br />
          &nbsp;{`width: 300px;`}
          <br />
          &nbsp;{`height: 200px;`}
          <br />
          &nbsp;{`border: 2px solid red;`}
          <br />
          &nbsp;{`border-radius: 10px;`}
          <br />
          {` }`}
          <br />
        </code>
        <h2>Wpływ ramki HTML na SEO</h2>
        <p>
          SEO (Search Engine Optimization) to proces optymalizacji strony pod kątem jej widoczności w wynikach
          wyszukiwania. W praktyce oznacza to stosowanie różnych technik i strategii mających na celu poprawę pozycji
          strony w rankingu wyszukiwarek, takich jak Google czy Bing. W tej części artykułu omówimy, jak ramki w HTML
          wpływają na SEO.
        </p>
        <p>
          Przede wszystkim należy zauważyć, że{" "}
          <strong>
            większość współczesnych wyszukiwarek nie radzi sobie dobrze z indeksowaniem stron opartych na ramkach
          </strong>
          . Wynika to z faktu, że każda sekcja ramki jest traktowana jako osobna strona internetowa, co utrudnia
          prawidłowe zrozumienie struktury witryny przez roboty indeksujące. W efekcie, strona oparta na ramkach może
          być gorzej oceniana przez algorytmy wyszukiwarek, co przekłada się na niższą pozycję w wynikach wyszukiwania.
        </p>
        <p>
          Kolejnym problemem związanym z ramkami w kontekście SEO jest{" "}
          <strong>utrudnione linkowanie do konkretnych podstron</strong>. Przykładowo, jeśli użytkownik chce udostępnić
          link do konkretnej podstrony opartej na ramkach, może się okazać, że odbiorca tego linka zobaczy jedynie pustą
          stronę z ramkami, bez żadnej treści. Taka sytuacja może prowadzić do frustracji użytkowników i zwiększać
          współczynnik odrzuceń, co również wpływa negatywnie na pozycjonowanie strony.
        </p>
        <h2>Jakie alternatywy wybrać dla ramek?</h2>
        <p>
          W związku z powyższymi problemami, wiele osób rezygnuje z używania ramki w HTML na rzecz innych technologii.
          Jedną z popularnych alternatyw są tzw. "<strong>divy</strong>" – elementy strukturalne języka HTML, które
          pozwalają na tworzenie podobnych efektów wizualnych jak ramki, ale bez ich negatywnego wpływu na SEO. Divy są
          łatwe w implementacji i obsługiwane przez wszystkie nowoczesne przeglądarki internetowe.
        </p>
        <p>
          Inną opcją jest wykorzystanie technologii <strong>AJAX</strong> (Asynchronous JavaScript and XML), która
          pozwala na dynamiczne ładowanie treści strony bez konieczności przeładowywania całej witryny. Dzięki AJAX
          można uzyskać podobne efekty jak przy użyciu ramki, ale zdecydowanie lepiej współpracujące z wyszukiwarkami
          internetowymi.
        </p>
        <h2>Ramki HTML – podsumowanie</h2>
        <p>
          Ramki w HTML to technika, która choć kiedyś była popularna, obecnie jest raczej odradzana ze względu na jej{" "}
          <strong>negatywny wpływ na SEO</strong>. Warto zatem rozważyć inne, bardziej nowoczesne i przyjazne dla
          wyszukiwarek technologie, takie jak divy czy AJAX, które pozwolą na osiągnięcie podobnych efektów wizualnych,
          ale bez strat dla pozycjonowania strony w wynikach wyszukiwania.
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/ramki.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
